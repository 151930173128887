<template>
  <div style="display: inline-block">
    {{ remainTimeToFormat }}
  </div>
</template>

<script>
export default {
  name: 'CountDown',
  props: {
    remainTimestamp: {
      type: Number,
      required: true
    },
    formatType: {
      type: Number,
      required: true,
      default: 1,
    }
  },
  data() {
    return {
      timeLeft: this.calculateTimeLeft(),
      isTimeUp: false,
      timer: null,
      remainTimestampBackup: 0,
    };
  },
  computed:{
    remainTimeToFormat() {
      let timeString = '';
      if( this.formatType === 1 ){
        timeString += this.timeLeft.hours > 0 ? this.timeLeft.hours + 'h : ' : '';
        timeString += this.timeLeft.hours > 0  || this.timeLeft.minutes > 0 ? this.timeLeft.minutes + 'm : ' : '';
        timeString += this.timeLeft.seconds >= 0 && !this.isTimeUp ? this.timeLeft.seconds + 's ' : '';
        timeString += this.isTimeUp ? '다음 핸드 블라인드업' : '';
      }else if( this.formatType === 2 ){
        const minutes = this.timeLeft.minutes.toString(); // 전체 분 수
        const seconds = this.timeLeft.seconds.toString(); // 나머지 초 수
        if( this.isTimeUp ) timeString = '다음 핸드 블라인드업';
        else                timeString =  `${minutes.padStart(2, '0')} : ${seconds.padStart(2, '0')}`;
      }
      return timeString;
    }
  },
  methods: {
    calculateTimeLeft() {
      if( this.remainTimestamp < 0 ){
        return { hours: 0, minutes: 0, seconds: 0 };
      }

      const targetTime = new Date(this.remainTimestamp);
      const now = new Date();
      const difference = targetTime - now;

      if (difference < 0) {
        this.isTimeUp = true;
        return { hours: 0, minutes: 0, seconds: 0 };
      }

      return {
        hours: Math.floor(difference / (1000 * 60 * 60)),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timeLeft = this.calculateTimeLeft();
      //시간이 변동되었을경우 초기화
      if( this.remainTimestampBackup !== this.remainTimestamp ){
        this.remainTimestampBackup = this.remainTimestamp;
        this.isTimeUp = false;
      }
    }, 1000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  }
};
</script>